import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { Modal, Box, Button, Breadcrumbs, Link, Paper, Typography, Skeleton } from '@mui/material';

const UserDetails = ({ handleError, handleSuccess }) => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleLockUser = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ user: { locked_at: user.locked_at ? null : new Date() } }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setUser(data);
        setOpenConfirmationModal(false);
        handleSuccess(`User ${user.locked_at ? 'unlocked' : 'locked'} successfully`);
      } else {
        const data = await response.json();
        if (data.error) {
          handleError(`Error ${user.locked_at ? 'unlocking' : 'locking'} user: ${data.error}`);
        } else {
          handleError(`Error ${user.locked_at ? 'unlocking' : 'locking'} user`);
        }
      }
    } catch (error) {
      handleError(`Error ${user.locked_at ? 'unlocking' : 'locking'} user: ${error}`);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  const fetchUser = async () => {
    try {
      const response = await fetch(`/api/v1/users/${id}`);
      const data = await response.json();
      setUser(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ padding: '24px 0' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '1rem' }}>
          <Link component={RouterLink} to="/records" color="inherit">
            Home
          </Link>
          <Link component={RouterLink} to="/users" color="inherit">
            User management
          </Link>
          <Skeleton width={100} />
        </Breadcrumbs>

        <Typography variant="h4" align="center" gutterBottom>
          <Skeleton />
        </Typography>
        <Box sx={{ marginTop: '1rem' }}>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
        </Box>
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ padding: '24px 0' }}>
        <Paper elevation={3} sx={{ padding: '2rem' }}>
          <Typography variant="h4" align="center" gutterBottom>
            User not found
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        <Link component={RouterLink} to="/users" color="inherit">
          User management
        </Link>
        <Typography color="textPrimary">{user.full_name}</Typography>
      </Breadcrumbs>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          {user.full_name}
        </Typography>
      </Box>
      <Paper sx={{ padding: '16px', margin: '24px 0', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Role</Typography>
            <Typography variant="body1">{user.role}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Full name</Typography>
            <Typography variant="body1">{user.full_name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Email</Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Branches</Typography>
            <Typography variant="body1">
              {user.branches.map((branch) => branch.name).join(', ')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Created at</Typography>
            <Typography variant="body1">{user.created_at}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button component={RouterLink} to={`/users/${user.id}/edit`} color="secondary" variant="text" sx={{ mr: '16px' }}>Edit</Button>
            <Button color="error" variant="text" onClick={() => setOpenConfirmationModal(true)}>
              {user.locked_at ? 'Unlock' : 'Lock'}
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="outlined"
          color="info"
          onClick={() => navigate('/users')}
          sx={{ marginTop: '0' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/users')}
          sx={{ marginTop: '0' }}
        >
          OK
        </Button>
      </Box>
      <Modal open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: '400px',
          maxWidth: '100%',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Confirm {user.locked_at ? 'Unlock' : 'Lock'}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to {user.locked_at ? 'unlock' : 'lock'} this user?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleLockUser}>
              {user.locked_at ? 'Unlock' : 'Lock'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserDetails;