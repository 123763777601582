import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Container, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import { BranchProvider } from '../contexts/BranchContext';
import Header from './Header';
import LoginForm from './LoginForm';
import RecordList from './RecordList';
import AddClientForm from './AddClientForm';
import ShowClient from './ShowClient';
import AddDrivingLicense from './AddDrivingLicense';
import AddDocument from './AddDocument';
import AddAddress from './AddAddress';
import UserManagement from './UserManagement';
import BranchManagement from './BranchManagement';
import AddUserForm from './AddUserForm';
import UserDetails from './UserDetails';
import ResetPassword from './ResetPassword';
import PinCodeOverlay from './PinCodeOverlay';
import EditUserProfile from './EditUserProfile';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [showPinCodeOverlay, setShowPinCodeOverlay] = useState(
    localStorage.getItem('showPinCodeOverlay') === 'true'
  );
  let [lastResetTime, setLastResetTime] = useState(null);

  const INACTIVITY_TIMEOUT = 300000; // 60 seconds * 5 = 5 minutes

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSuccess = (message) => {
    setSuccessMessage(message);
    setOpenSuccessSnackbar(true);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  // Check if the user is authenticated
  const checkAuthentication = async () => {
    try {
      const response = await fetch('/api/v1/authenticated');
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(data.authenticated);
        setUser({ email: data.email, full_name: data.full_name, role: data.role, branches: data.branches, use_pin_code: data.use_pin_code, pin_code: data.pin_code });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleLogout = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/logout', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        }
      });
      if (response.ok) {
        setIsLoggedIn(false);
        localStorage.setItem('showPinCodeOverlay', 'false');
        setUser(null);
      }
    } catch (error) {
      console.error('Error:', error);
    } 
  };

  const resetInactivityTimer = () => {
    const currentTime = Date.now();
    if (lastResetTime && (currentTime - lastResetTime < 1000)) {
      // If the last reset was less than 1 second ago, do nothing
      return;
    }
    lastResetTime = currentTime;
    setLastResetTime(currentTime);
    //console.log('timer reset');
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      //console.log('check', currentTime - lastResetTime)
      if (currentTime - lastResetTime > INACTIVITY_TIMEOUT) {
        if (user && user.use_pin_code == true) {
          localStorage.setItem('showPinCodeOverlay', 'true');
          setShowPinCodeOverlay(true);
        }
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(timer);
    };
  }, [user]);

  useEffect(() => {
    resetInactivityTimer();
  }, []);

  useEffect(() => {
    const resetTimer = () => {
      resetInactivityTimer();
    };
  
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('touchstart', resetTimer);
    window.addEventListener('touchmove', resetTimer);
  
    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
      window.removeEventListener('touchmove', resetTimer);
      console.log('timer collected and cleared')
    };
  }, [user]);

  const handleUnlockPinCode = () => {
    localStorage.setItem('showPinCodeOverlay', 'false');
    setShowPinCodeOverlay(false);
    resetInactivityTimer();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <BranchProvider>
      <Router>
        <Box sx={{ backgroundColor: '#F4F5F6', minHeight: '100vh' }}>
          {isLoggedIn && user && <Header user={user} onLogout={handleLogout} />}
          <Container maxWidth="lg" sx={{ maxWidth: '1152px', margin: '0 auto' }}>
            {isLoggedIn && user && user.use_pin_code && showPinCodeOverlay && (
              <PinCodeOverlay user={user} onUnlock={handleUnlockPinCode} onLogout={handleLogout} />
            )}
            <Routes>
              {isLoggedIn ? (
                <>
                  <Route path="/records" element={<RecordList handleError={handleError} user={user} />} />
                  <Route path="/clients/new" element={<AddClientForm handleError={handleError} user={user} />} />
                  <Route path="/clients/:id" element={<ShowClient handleError={handleError} user={user} />} />
                  <Route path="/clients/:id/documents/new_address" element={<AddAddress handleError={handleError} />} />
                  <Route path="/clients/:id/documents/:documentId/edit_address" element={<AddAddress handleError={handleError}  />} />
                  <Route path="/clients/:id/documents/new_license" element={<AddDrivingLicense handleError={handleError} />} />
                  <Route path="/clients/:id/documents/new" element={<AddDocument handleError={handleError} />} />

                  <Route path="/users" element={<UserManagement handleError={handleError} handleSuccess={handleSuccess} user={user} />} />
                  <Route path="/users/new" element={<AddUserForm handleError={handleError} handleSuccess={handleSuccess} />} />
                  <Route path="/users/:id" element={<UserDetails handleError={handleError} handleSuccess={handleSuccess} />} />
                  <Route path="/users/:id/edit" element={<AddUserForm handleError={handleError} handleSuccess={handleSuccess} />} />
                  <Route path="/profile" element={<EditUserProfile handleError={handleError} handleSuccess={handleSuccess} checkAuthentication={checkAuthentication} handleUnlockPinCode={handleUnlockPinCode} />} />
                  {user.role === 'admin' && (
                    <Route path="/branches" element={<BranchManagement handleError={handleError} handleSuccess={handleSuccess} />} />
                  )}
                  <Route path="*" element={<Navigate to="/records" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<LoginForm checkAuthentication={checkAuthentication} handleUnlockPinCode={handleUnlockPinCode} />} />
                  <Route path="/reset_password" element={<ResetPassword />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              )}
            </Routes>
          </Container>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSuccessSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSuccessSnackbar} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      </Router>
    </BranchProvider>
  );
};

export default App;