import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, FormControlLabel, Checkbox, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EditUserProfile = ({ handleError, handleSuccess, checkAuthentication, handleUnlockPinCode }) => {
  const [full_name, setFullName] = useState('');
  const [use_pin_code, setUsePinCode] = useState(false);
  const [pin_code, setPinCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await fetch('/api/v1/profile');
      const data = await response.json();
      setFullName(data.full_name);
      setUsePinCode(data.use_pin_code);
      setPinCode(data.pin_code);
    } catch (error) {
      handleError(`Error fetching user profile: ${error}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (use_pin_code && !pin_code) {
      handleError('PIN code is required when "Use PIN Code" is enabled.');
      return;
    }
    
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/profile', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ user: { full_name, use_pin_code, pin_code } }),
      });

      if (response.ok) {
        handleSuccess('Profile updated successfully');
        checkAuthentication();
        handleUnlockPinCode(); // just do it because setting use_pin_code might instantly show the prompt
        navigate('/records');
      } else {
        const data = await response.json();
        handleError(`Error updating profile: ${data.error}`);
      }
    } catch (error) {
      handleError(`Error updating profile: ${error}`);
    }
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          Edit Profile
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Paper sx={{ padding: '1rem', margin: '0 auto', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Name"
                value={full_name}
                onChange={(e) => setFullName(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={use_pin_code}
                    onChange={(e) => setUsePinCode(e.target.checked)}
                  />
                }
                label="Use PIN Code"
              />
            </Grid>
            {use_pin_code && (
              <Grid item xs={12}>
                <TextField
                  sx={{ marginTop: '0', marginBottom: '0' }}
                  label="PIN Code"
                  type="number"
                  value={pin_code}
                  onChange={(e) => setPinCode(e.target.value)}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="outlined"
            color="info"
            onClick={() => navigate('/records')}
            sx={{ marginTop: '0' }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: '0' }}
          >
            Save
          </Button>
        </Box>
      </form>
      <Box sx={{ mt: 4 }}>
        <Typography variant="body2">
          If you want to update your password, please log out and use the "Forgot password" function.
        </Typography>
      </Box>
    </Box>
  );
};

export default EditUserProfile;