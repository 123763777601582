import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, ButtonGroup, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import AdminNavigation from './shared/AdminNavigation';

const BranchManagement = ({ handleError, handleSuccess }) => {
  const [branches, setBranches] = useState([]);
  const [newBranch, setNewBranch] = useState({ name: '', code: '' });
  const [editingBranch, setEditingBranch] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deletingBranch, setDeletingBranch] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await fetch('/api/v1/branches');
      if (response.ok) {
        const data = await response.json();
        setBranches(data);
      } else {
        throw new Error('Failed to fetch branches');
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handleCreateBranch = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/branches', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ branch: newBranch }),
      });
      if (response.ok) {
        handleSuccess('Branch created successfully');
        setNewBranch({ name: '', code: '' });
        fetchBranches();
      } else {
        const data = await response.json();
        throw new Error(formatErrorMessages(data));
      }
    } catch (error) {
      handleError(error.message);
    }
  };
  
  const handleUpdateBranch = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/branches/${editingBranch.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ branch: editingBranch }),
      });
      if (response.ok) {
        handleSuccess('Branch updated successfully');
        setEditingBranch(null);
        fetchBranches();
      } else {
        const data = await response.json();
        throw new Error(formatErrorMessages(data));
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const formatErrorMessages = (data) => {
    if (data && typeof data === 'object') {
      return Object.entries(data)
        .map(([field, messages]) => {
          const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
          return Array.isArray(messages) 
            ? `${capitalizedField} ${messages.join(', ')}`
            : `${capitalizedField} ${messages}`;
        })
        .join('. ');
    }
    return 'An error occurred while processing your request';
  };

  const handleDeleteBranch = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/branches/${deletingBranch.id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      });
      if (response.ok) {
        handleSuccess('Branch deleted successfully');
        setDeletingBranch(null);
        fetchBranches();
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete branch');
      }
    } catch (error) {
      handleError(error.message);
    }
    setOpenDialog(false);
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Box sx={{ padding: '12px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <AdminNavigation />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          Branch Management
        </Typography>
      </Box>
      <Paper sx={{ padding: '16px', marginBottom: '16px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Typography variant="h6" gutterBottom>
          Create New Branch
        </Typography>
        <Box sx={{ display: 'flex', gap: '16px', marginBottom: '0' }}>
          <TextField
            label="Branch Name"
            size="small"
            value={newBranch.name}
            onChange={(e) => setNewBranch({ ...newBranch, name: e.target.value })}
          />
          <TextField
            label="Branch Code"
            size="small"
            value={newBranch.code}
            onChange={(e) => setNewBranch({ ...newBranch, code: e.target.value })}
          />
          <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={handleCreateBranch}>
            Create Branch
          </Button>
        </Box>
      </Paper>
      <TableContainer component={Paper} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Has Clients</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branches.map((branch) => (
              <TableRow key={branch.id}>
                <TableCell>
                  {editingBranch && editingBranch.id === branch.id ? (
                    <TextField
                      size="small"
                      value={editingBranch.name}
                      onChange={(e) => setEditingBranch({ ...editingBranch, name: e.target.value })}
                    />
                  ) : (
                    branch.name
                  )}
                </TableCell>
                <TableCell>
                  {editingBranch && editingBranch.id === branch.id ? (
                    <TextField
                      size="small"
                      value={editingBranch.code}
                      onChange={(e) => setEditingBranch({ ...editingBranch, code: e.target.value })}
                    />
                  ) : (
                    branch.code
                  )}
                </TableCell>
                <TableCell>
                  {branch.has_clients ? (
                    <CheckIcon color="success" />
                  ) : (
                    <span></span>
                  )}
                </TableCell>
                <TableCell>
                  {editingBranch && editingBranch.id === branch.id ? (
                    <>
                      <Button color="info" size="small" variant="outlined" sx={{ mr: '12px' }} onClick={handleUpdateBranch}>Save</Button>
                      <Button color="info" size="small" variant="outlined" onClick={() => setEditingBranch(null)}>Cancel</Button>
                    </>
                  ) : (
                    <>
                      <Button color="info" size="small" variant="outlined" sx={{ mr: '12px' }} onClick={() => setEditingBranch(branch)}>Edit</Button>
                      <Button color="error" size="small" variant="outlined" onClick={() => {
                        setDeletingBranch(branch);
                        setOpenConfirmationModal(true);
                      }}>Delete</Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: '400px',
          maxWidth: '100%',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Confirm Delete
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete the branch "{deletingBranch?.name}"? This action cannot be undone.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleDeleteBranch}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BranchManagement;