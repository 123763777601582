// app/javascript/components/AdminNavigation.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ButtonGroup, Button, Box, useMediaQuery, useTheme } from '@mui/material';

const AdminNavigation = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navItems = [
    { path: '/records', label: 'Manifest' },
    { path: '/users', label: 'User Management' },
    { path: '/branches', label: 'Branch Management' },
  ];

  const buttonProps = (path) => ({
    component: Link,
    to: path,
    color: "info",
    variant: location.pathname.startsWith(path) ? 'contained' : 'outlined',
    fullWidth: isMobile,
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 1 }}>
      {isMobile ? (
        navItems.map((item) => (
          <Button key={item.path} {...buttonProps(item.path)}>
            {item.label}
          </Button>
        ))
      ) : (
        <ButtonGroup>
          {navItems.map((item) => (
            <Button key={item.path} {...buttonProps(item.path)}>
              {item.label}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </Box>
  );
};

export default AdminNavigation;