import React, { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Link, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ checkAuthentication, handleUnlockPinCode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
  const [show2FAForm, setShow2FAForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setErrorMessage('');
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ user: { email, password } }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.extra && data.extra === '2fa') {
          setShow2FAForm(true);
        } else if (data.message === 'Signed in successfully.') {
          document.querySelector('meta[name="csrf-token"]').setAttribute('content', data.csrf_token);
          checkAuthentication();
          handleUnlockPinCode();
          navigate('/records');
        }
      } else {
        const data = await response.json();
        setErrorMessage(data.error);
        console.error('Authentication failed:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Network error, try again later');
    } finally {
      setIsLoading(false);
    }
  };

  const handle2FASubmit = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/verify_2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ email, otp_code: otpCode }),
      });

      if (response.ok) {
        const data = await response.json();
        document.querySelector('meta[name="csrf-token"]').setAttribute('content', data.csrf_token);
        checkAuthentication();
        navigate('/records');
      } else {
        const data = await response.json();
        setErrorMessage(data.error);
        console.error('2FA verification failed:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Network error, try again later');
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/forgot_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ email: forgotPasswordEmail }),
      });

      if (response.ok) {
        setForgotPasswordMessage('If an account with this email exists, we have sent a password reset link.');
      } else {
        setForgotPasswordMessage('An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setForgotPasswordMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '48px' }}>
        <img src="/images/logo.svg" alt="Logo" />
      </Box>
      <Paper sx={{ padding: '1rem', width: '408px', maxWidth: '100%', margin: '0 auto', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        {showForgotPassword ? (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Reset Password
            </Typography>
            <form onSubmit={handleForgotPasswordSubmit}>
              <TextField
                sx={{ marginTop: '16px', marginBottom: '0' }}
                label="Email"
                type="email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              {forgotPasswordMessage && (
                <Alert severity="info" sx={{ marginTop: '16px', marginBottom: '0' }}>
                  {forgotPasswordMessage}
                </Alert>
              )}
              <Box sx={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>
                <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                  Reset Password
                </Button>
              </Box>
              <Box sx={{ textAlign: 'left', fontFamily: 'Roboto' }}>
                <Link color="primary" onClick={() => setShowForgotPassword(false)} sx={{ cursor: 'pointer' }}>
                  Back to Login
                </Link>
              </Box>
            </form>
          </>
        ) : show2FAForm ? (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Enter 2FA Code
            </Typography>
            <form onSubmit={handle2FASubmit}>
              <TextField
                sx={{ marginTop: '16px', marginBottom: '0' }}
                label="2FA Code"
                type="text"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              {errorMessage && (
                <Alert severity="error" sx={{ marginTop: '16px', marginBottom: '0' }}>
                  {errorMessage}
                </Alert>
              )}
              <Box sx={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>
                <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                  Verify
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              Log In
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                sx={{ marginTop: '16px', marginBottom: '0' }}
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                sx={{ marginTop: '16px', marginBottom: '0' }}
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              {errorMessage && (
                <Alert severity="error" sx={{ marginTop: '16px', marginBottom: '0' }}>
                  {errorMessage}
                </Alert>
              )}
              <Box sx={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>
                <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isLoading}>
                  {isLoading ? 'Logging in...' : 'LOG IN'}
                </Button>
              </Box>
              <Box sx={{ textAlign: 'left', fontFamily: 'Roboto' }}>
                <Link color="primary" onClick={() => setShowForgotPassword(true)} sx={{ cursor: 'pointer' }}>
                  Forgot password?
                </Link>
              </Box>
            </form>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default LoginForm;