// app/javascript/packs/application.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import App from '../components/App';
import theme from '../theme';

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('root');
  
  if (rootElement) {
    ReactDOM.createRoot(rootElement).render(
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    );
  }
});