import React, { useEffect, useState, useContext } from 'react';
import { TextField, Grid, FormControl, InputAdornment, InputLabel, ButtonGroup, Box, Typography, Paper, TableContainer, TablePagination, Table, TableHead, TableBody, TableRow, TableCell, Button, Select, MenuItem, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import AdminNavigation from './shared/AdminNavigation';
import { BranchContext } from '../contexts/BranchContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const UserManagement = ({ handleError, handleSuccess, user }) => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [page, setPage] = useState(0); // current page number
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100); // number of rows per page
  const navigate = useNavigate(); // Add this line
  const { branches } = useContext(BranchContext);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortColumn, setSortColumn] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, searchQuery, selectedBranch, selectedRole, sortColumn, sortDirection]);

  const fetchUsers = async () => {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('page', page + 1);
      searchParams.append('per_page', rowsPerPage);
      searchParams.append('sort_column', sortColumn);
      searchParams.append('sort_direction', sortDirection);

      if (searchQuery) {
        searchParams.append('search', searchQuery);
      }

      if (selectedBranch) {
        searchParams.append('branch_ids', selectedBranch);
      }

      if (selectedRole) {
        searchParams.append('role', selectedRole);
      }

      const response = await fetch(`/api/v1/users?${searchParams.toString()}`);
      const data = await response.json();
      setUsers(data.users);
      setTotalCount(data.total_count);
    } catch (error) {
      handleError(`Error fetching users: ${error}`);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setSelectedBranch('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page number when changing rows per page
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
    setPage(0);
  };

  const handleLockUser = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/users/${selectedUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ user: { locked_at: selectedUser.locked_at ? null : new Date() } }),
      });

      if (response.ok) {
        const data = await response.json();
        setUsers((prevUsers) =>
          prevUsers.map((user) => (user.id === data.id ? data : user))
        );
        setOpenConfirmationModal(false);
        handleSuccess(`User ${selectedUser.locked_at ? 'unlocked' : 'locked'} successfully`);
      } else {
        const data = await response.json();
        if (data.error) {
          handleError(`Error ${selectedUser.locked_at ? 'unlocking' : 'locking'} user: ${data.error}`);
        } else {
          handleError(`Error ${selectedUser.locked_at ? 'unlocking' : 'locking'} user`);
        }
      }
    } catch (error) {
      handleError(`Error ${selectedUser.locked_at ? 'unlocking' : 'locking'} user: ${error}`);
    }
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Box sx={{ padding: '12px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <AdminNavigation />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          Users
        </Typography>
        <Button component={Link} to="/users/new" variant="contained" startIcon={<AddIcon />}>
          ADD NEW USER
        </Button>
      </Box>
      <Grid container spacing={2} alignItems="center" sx={{ mb: '32px' }}>
        <Grid item md={3} sm={4} xs={12}>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearch}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                backgroundColor: '#FFFFFF',
              },
            }}
          />
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          {user && user.role === 'admin' && (
            <FormControl fullWidth>
              <InputLabel id="branch-label">Branch</InputLabel>
              <Select
                labelId="branch-label"
                value={selectedBranch}
                defaultValue=""
                onChange={handleBranchChange}
                label="Branch"
                fullWidth
                sx={{ backgroundColor: '#FFFFFF' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              value={selectedRole}
              onChange={handleRoleChange}
              label="Role"
              fullWidth
              sx={{ backgroundColor: '#FFFFFF' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="moderator">Moderator</MenuItem>
              <MenuItem value="agent">Agent</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={12} xs={12} display="flex" justifyContent="flex-end">
          <Button variant="text" color="secondary" onClick={handleResetFilters}>
            Reset All Filters
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort('full_name')} sx={{ whiteSpace: 'nowrap' }}>
                Name {sortColumn === 'full_name' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('email')} sx={{ whiteSpace: 'nowrap' }}>
                Email {sortColumn === 'email' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('role')} sx={{ whiteSpace: 'nowrap' }}>
                Role {sortColumn === 'role' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('branch')} sx={{ whiteSpace: 'nowrap' }}>
                Branch {sortColumn === 'branch' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('use_pin_code')} sx={{ whiteSpace: 'nowrap' }}>
                Uses PIN {sortColumn === 'use_pin_code' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('created_at')} sx={{ whiteSpace: 'nowrap' }}>
                Created at {sortColumn === 'created_at' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      width: { xs: '200px', sm: 'auto' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: user.branches.map((branch) => `<code>${branch.name}</code>`).join(', '),
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {user.use_pin_code ? (
                    <CheckIcon color="success" />
                  ) : (
                    <span></span>
                  )}
                </TableCell>
                <TableCell>
                  {format(new Date(user.created_at), 'dd.MM.yyyy')}
                </TableCell>
                <TableCell>
                  <Box
                      sx={{
                        width: '240px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                    <Button component={Link} to={`/users/${user.id}`} color="info" size="small" variant="outlined" sx={{ mr: '12px' }}>View</Button>
                    <Button component={Link} to={`/users/${user.id}/edit`} color="info" size="small" variant="outlined" sx={{ mr: '12px' }}>Edit</Button>
                    <Button color="error" size="small" variant="outlined" onClick={() => {
                      setSelectedUser(user);
                      setOpenConfirmationModal(true);
                    }}>
                      {user.locked_at ? 'Unlock' : 'Lock'}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: '400px',
          maxWidth: '100%',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Confirm {selectedUser?.locked_at ? 'Unlock' : 'Lock'}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to {selectedUser?.locked_at ? 'unlock' : 'lock'} {selectedUser?.full_name || selectedUser?.email}?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleLockUser}>
              {selectedUser?.locked_at ? 'Unlock' : 'Lock'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserManagement;