import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BranchContext } from '../contexts/BranchContext';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
  Box,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material';
import AdminNavigation from './shared/AdminNavigation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import dayjs from 'dayjs';

const RecordList = ({ handleError, user }) => {
  const [initialFiltersSet, setInitialFiltersSet] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [page, setPage] = useState(0); // current page number
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50); // number of rows per page
  const navigate = useNavigate();
  const { branches } = useContext(BranchContext);
  const [sortColumn, setSortColumn] = useState('booked_for');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleSort = (column) => {
    let newSortDirection = 'asc';
    if (sortColumn === column && sortDirection === 'asc') {
      newSortDirection = 'desc';
    }
    setSortColumn(column);
    setSortDirection(newSortDirection);
    setPage(0);
  };

  useEffect(() => {
    const storedSelectedDate = localStorage.getItem('selectedDate') ? dayjs(localStorage.getItem('selectedDate')) : null;
    const storedSelectedBranch = localStorage.getItem('selectedBranch') || '';
    const storedSelectedStatus = localStorage.getItem('selectedStatus') || '';
    const storedSortColumn = localStorage.getItem('sortColumn') || 'booked_for';
    const storedSortDirection = localStorage.getItem('sortDirection') || 'asc';

    setSelectedDate(storedSelectedDate);
    setSelectedBranch(storedSelectedBranch);
    setSortColumn(storedSortColumn);
    setSortDirection(storedSortDirection);
    setSelectedStatus(storedSelectedStatus);

    setInitialFiltersSet(true);
  }, []);

  // useEffect(() => {
  //   localStorage.setItem('searchQuery', searchQuery);
  // }, [searchQuery]);

  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate ? selectedDate.format('YYYY-MM-DD') : '');
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('selectedStatus', selectedStatus);
  }, [selectedStatus]);

  useEffect(() => {
    localStorage.setItem('selectedBranch', selectedBranch);
  }, [selectedBranch]);

  useEffect(() => {
    localStorage.setItem('sortColumn', sortColumn);
    localStorage.setItem('sortDirection', sortDirection);
  }, [sortColumn, sortDirection]);

  useEffect(() => {
    if (initialFiltersSet) {
      fetchClients();
    }
  }, [initialFiltersSet, page, rowsPerPage, searchQuery, selectedBranch, selectedDate, selectedStatus, sortColumn, sortDirection]);

  const fetchClients = async () => {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('page', page + 1);
      searchParams.append('per_page', rowsPerPage);
      searchParams.append('sort_column', sortColumn);
      searchParams.append('sort_direction', sortDirection);
  
      if (selectedDate && selectedDate.$isDayjsObject && selectedDate.isValid()) {
        searchParams.append('date', selectedDate.format('YYYY-MM-DD'));
      }

      if (searchQuery) {
        searchParams.append('search', searchQuery);
      }
  
      if (selectedBranch) {
        searchParams.append('branch_id', selectedBranch);
      }
  
      if (selectedStatus) {
        searchParams.append('status', selectedStatus);
      }
  
      const response = await fetch(`/api/v1/clients?${searchParams.toString()}`);
      const data = await response.json();
      setClients(data.clients);
      setTotalCount(data.total_count);
    } catch (error) {
      console.error('Error fetching clients:', error);
      handleError(`Error fetching clients: ${error}`);
    }
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedStatus(selectedStatus);
  };
  
  const handleBranchChange = (event) => {
    const selectedBranch = event.target.value;
    setSelectedBranch(selectedBranch);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setSelectedDate(null);
    setSelectedBranch('');
    setSelectedStatus('O')
    //localStorage.removeItem('searchQuery');
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('selectedBranch');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page number when changing rows per page
  };

  const handleAddClient = () => {
    navigate('/clients/new');
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      {user && user.role === 'admin' && (
        <Box sx={{ padding: '12px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <ButtonGroup>
            <AdminNavigation />
          </ButtonGroup>
        </Box>
      )}
      {user && user.role !== 'admin' && (
        <Box sx={{ padding: '12px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1">
            Manifest
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {user && user.role === 'admin' && (
          <Typography variant="h5" component="h2">
            Clients
          </Typography>
        )}
        {user && user.role !== 'admin' && (
          <Typography variant="h5" component="h2">
            Clients ({user.branches.map((branch) => branch.name).join(', ')})
          </Typography>
        )}
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddClient}>
          WALK UP
        </Button>
      </Box>
      <Grid container spacing={2} alignItems="center" sx={{ mb: '32px' }}>
        <Grid item md={3} sm={user && user.role === 'admin' ? 3 : 6} xs={12}>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearch}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                backgroundColor: '#FFFFFF',
              },
            }}
          />
        </Grid>
        <Grid item md={2} sm={user && user.role === 'admin' ? 3 : 6} xs={user && user.role === 'admin' ? 6 : 12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              value={selectedDate}
              onChange={handleDateChange}
              variant="outlined"
              sx={{ width: '100%', backgroundColor: '#FFFFFF' }}
              format="DD/MM/YYYY"
              textField={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ backgroundColor: '#FFFFFF' }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={2} sm={user && user.role === 'admin' ? 3 : 6} xs={6}>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={selectedStatus}
              defaultValue=""
              onChange={handleStatusChange}
              label="Status"
              fullWidth
              sx={{ backgroundColor: '#FFFFFF' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="O">Open</MenuItem>
              <MenuItem value="F">Filled</MenuItem>
              <MenuItem value="N">No Show</MenuItem>
              <MenuItem value="C">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} sm={user && user.role === 'admin' ? 3 : 6} xs={12}>
          {user && user.role === 'admin' && (
            <FormControl fullWidth>
              <InputLabel id="branch-label">Branch</InputLabel>
              <Select
                labelId="branch-label"
                value={selectedBranch}
                defaultValue=""
                onChange={handleBranchChange}
                label="Branch"
                fullWidth
                sx={{ backgroundColor: '#FFFFFF' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item md={3} sm={12} xs={12} display="flex" justifyContent="flex-end">
          <Button variant="text" color="secondary" onClick={handleResetFilters}>
            Reset All Filters
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort('name')}>
                Name {sortColumn === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Booking ref</TableCell>
              <TableCell>Status</TableCell>
              <TableCell onClick={() => handleSort('branch')}>
                Branch {sortColumn === 'branch' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('booked_for')}>
                Time {sortColumn === 'booked_for' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
              <TableCell onClick={() => handleSort('latest_document_at')}>
                ID Processed {sortColumn === 'latest_document_at' && (sortDirection === 'asc' ? '▲' : '▼')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow
                key={client.id}
                onClick={() => navigate(`/clients/${client.id}`)}
                sx={{
                  backgroundColor: client.booking_status === 'F' && !client.latest_document ? 'rgba(255, 0, 0, 0.1)' : 'inherit',
                }}
              >
                <TableCell
                  sx={{
                    color: 'secondary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'secondary.dark',
                    },
                  }}
                >
                  {client.name}
                </TableCell>
                <TableCell>{client.client_type}</TableCell>
                <TableCell>{client.booking_ref}</TableCell>
                <TableCell>{client.booking_status}</TableCell>
                <TableCell>{client.branch?.name}</TableCell>
                <TableCell>{client.booked_for}</TableCell>
                <TableCell>
                  {client.latest_document ? (
                    <Typography variant="caption" color="#00000099">
                      {format(new Date(client.latest_document.created_at), 'hh:mm a, dd.MM.yyyy')}
                      <br />
                      {client.latest_document_user?.email}
                    </Typography>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default RecordList;