import React, { createContext, useState, useEffect } from 'react';

export const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await fetch('/api/v1/branches');
      const data = await response.json();
      setBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  return (
    <BranchContext.Provider value={{ branches }}>
      {children}
    </BranchContext.Provider>
  );
};