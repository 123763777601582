import React, { useState, useContext } from 'react';
import { Box, Paper, Typography, TextField, Button, Grid, Select, MenuItem, Breadcrumbs, Link, FormControl, InputLabel } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { BranchContext } from '../contexts/BranchContext';

const AddClientForm = ({ handleError, user }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bookingRef, setBookingRef] = useState('');
  const [branch, setBranch] = useState(user?.branch_id || '');
  const [errors, setErrors] = useState({});
  const { branches } = useContext(BranchContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/clients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ client: { first_name: firstName, last_name: lastName, booking_ref: bookingRef, branch_id: branch } }),
      });
  
      if (response.ok) {
        const data = await response.json();
        navigate(`/clients/${data.id}`);
      } else {
        const data = await response.json();
        setErrors(data.errors || data || {});
      }
    } catch (error) {
      console.error('Error:', error);
      handleError(`An error occurred: ${error}`);
    }
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '1rem' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        <Typography color="textPrimary">New client</Typography>
      </Breadcrumbs>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          New client
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Paper sx={{ padding: '1rem', margin: '0 auto', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={errors.first_name !== undefined}
                helperText={errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={errors.last_name !== undefined}
                helperText={errors.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Booking Ref"
                value={bookingRef}
                onChange={(e) => setBookingRef(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={errors.booking_ref !== undefined}
                helperText={errors.booking_ref}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  value={branch}
                  labelId="branch-label"
                  onChange={(e) => setBranch(e.target.value)}
                  fullWidth
                  label="Branch"
                  defaultValue=""
                  sx={{ marginTop: '0', marginBottom: '0' }}
                  error={errors.branch_id !== undefined}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                              
                  {branches
                    .filter((branch) =>
                      user?.role === 'admin' ||
                      user?.branches?.some((userBranch) => userBranch.id === branch.id)
                    )
                    .map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.branch_id && (
                  <Typography variant="caption" color="error">
                    {errors.branch_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/records')}
            color="info"
            sx={{ marginTop: '0' }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: '0' }}
          >
            Create
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddClientForm;