// app/javascript/components/ResetPassword.js
import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button, Box, Alert } from '@mui/material';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch('/api/v1/reset_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ password, password_confirmation: passwordConfirmation, reset_password_token: searchParams.get('reset_password_token') }),
      });

      if (response.ok) {
        setSuccessMessage('Your password has been reset successfully. You will be redirected to the login page.');
        setTimeout(() => navigate('/'), 3000);
      } else {
        const data = await response.json();
        setErrorMessage(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}>
      <Paper sx={{ padding: '1rem', margin: '0 auto', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            sx={{ marginTop: '16px', marginBottom: '0' }}
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            sx={{ marginTop: '16px', marginBottom: '0' }}
            label="Confirm New Password"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          {errorMessage && (
            <Alert severity="error" sx={{ marginTop: '16px', marginBottom: '0' }}>
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ marginTop: '16px', marginBottom: '0' }}>
              {successMessage}
            </Alert>
          )}
          <Box sx={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>
            <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
              Reset Password
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default ResetPassword;