import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F9C606',
      dark: '#E0B206',
    },
    secondary: {
      main: '#1976D2'
    },
    info: {
      main: 'rgba(0, 0, 0, 0.87)',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

export default theme;