import React, { useState, useEffect, useContext } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField,
  FormControl, 
  InputLabel,
  InputAdornment, 
  IconButton, 
  Button, 
  Grid, 
  Select, 
  MenuItem, 
  Checkbox, 
  ListItemText,
  Breadcrumbs, 
  Link 
} from '@mui/material';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import { BranchContext } from '../contexts/BranchContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const AddUserForm = ({ handleError, handleSuccess }) => {
  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('moderator');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [errors, setErrors] = useState({});
  const { branches } = useContext(BranchContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (id) {
      fetchUser();
    }
  }, [id]);

  const handleBranchChange = (event) => {
    setSelectedBranches(event.target.value);
  };

  const fetchUser = async () => {
    try {
      const response = await fetch(`/api/v1/users/${id}`);
      const data = await response.json();
      console.log(data);
      setFullName(data.full_name);
      setEmail(data.email);
      setRole(data.role);
      setSelectedBranches(data.branches.map((branch) => branch.id));
    } catch (error) {
      handleError(`Error fetching user: ${error}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordError("Passwords don't match");
      return;
    }

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const url = id ? `/api/v1/users/${id}` : '/api/v1/users';
      const method = id ? 'PUT' : 'POST';

      const userData = { 
        user: { 
          full_name, 
          email, 
          role, 
          branch_ids: selectedBranches,
        }
      };

      if (password || !id) {
        userData.user.password = password;
        userData.user.password_confirmation = confirmPassword;
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        handleSuccess(id ? 'User updated successfully' : 'User created successfully');
        navigate('/users');
      } else {
        const data = await response.json();
        if (response.status === 403) {
          handleError(data.error);
        } else {
          setErrors(data.errors || data || {});
        }
      }
    } catch (error) {
      console.error('Error:', error);
      handleError(`An error occurred: ${error}`);
    }
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        <Link component={RouterLink} to="/users" color="inherit">
          User management
        </Link>
        <Typography color="textPrimary">{id ? 'Edit User' : 'New User'}</Typography>
      </Breadcrumbs>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          {id ? 'Edit User' : 'New User'}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Paper sx={{ padding: '1rem', margin: '0 auto', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Name"
                value={full_name}
                onChange={(e) => setFullName(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={errors.full_name !== undefined}
                helperText={errors.full_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={errors.email !== undefined}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
                variant="outlined"
                displayEmpty
                sx={{ marginTop: '0', marginBottom: '0' }}
                error={errors.role !== undefined}
              >
                <MenuItem value="" disabled>
                  Select Role
                </MenuItem>
                <MenuItem value="moderator">Moderator</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="agent">Agent</MenuItem>
              </Select>
              {errors.role && (
                <Typography variant="caption" color="error">
                  {errors.role}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl fullWidth>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  labelId="branch-label"
                  value={selectedBranches}
                  multiple
                  onChange={handleBranchChange}
                  fullWidth
                  defaultValue=""
                  renderValue={(selected) => selected.map((branchId) => branches.find((branch) => branch.id === branchId)?.name).join(', ')}
                  label="Branch"
                  sx={{ marginTop: '0', marginBottom: '0' }}
                  error={errors.branch_id !== undefined || errors.branches !== undefined}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      <Checkbox checked={selectedBranches.indexOf(branch.id) > -1} />
                      <ListItemText primary={branch.name} />
                    </MenuItem>
                  ))}
                </Select>
                {errors.branch_id && (
                  <Typography variant="caption" color="error">
                    {errors.branch_id}
                  </Typography>
                )}
                {errors.branches && (
                  <Typography variant="caption" color="error">
                    {errors.branches}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={passwordError !== ''}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                sx={{ marginTop: '0', marginBottom: '0' }}
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin="dense"
                variant="outlined"
                error={passwordError !== ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
            variant="outlined"
            color="info"
            onClick={() => navigate('/users')}
            sx={{ marginTop: '0' }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: '0' }}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddUserForm;