// app/javascript/components/AddDrivingLicense.js
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { 
  Hidden, 
  Grid, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Breadcrumbs, 
  Link, 
  Paper, 
  Typography, 
  Button, 
  IconButton, 
  Box, 
} from '@mui/material';
import { PhotoCamera, Close } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import { format } from 'date-fns';

const AddDrivingLicense = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const overlayRef = useRef(null);
  const [client, setClient] = useState(null);
  const [step, setStep] = useState(1);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [stream, setStream] = useState(null);
  const [videoAspectRatio, setVideoAspectRatio] = useState(null);
  const [overlayDimensions, setOverlayDimensions] = useState({ width: '80%', height: '65%' });
  const [savingDocument, setSavingDocument] = useState(false);

  const fetchClient = async () => {
    try {
      const response = await fetch(`/api/v1/clients/${id}`);
      const data = await response.json();
      setClient(data);
      //setLoading(false);
    } catch (error) {
      console.error('Error fetching client:', error);
      //setLoading(false);
    }
  };

  useEffect(() => {
    document.body.classList.add('hide-header');
  
    return () => {
      document.body.classList.remove('hide-header');
    };
  }, []);

  useEffect(() => {
    fetchClient();
  }, [id]);

  useEffect(() => {
    async function enableCamera() {
      const constraints = {
        video: {
          facingMode: 'environment',
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
      };
  
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        setStream(stream);
  
        const videoTrack = stream.getVideoTracks()[0];
        const videoSettings = videoTrack.getSettings();
        const { width, height } = videoSettings;
        console.log('Actual video dimensions:', width, height);
        // Optionally, you can update the state with the actual dimensions
        // setVideoDimensions({ width, height });
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    }
  
    if (step === 1 || step === 3) {
      enableCamera();
    }
  
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [step]);

  // to unload the camera on page leave
  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      calculateAspectRatio();
    };
  
    const calculateAspectRatio = () => {
      if (videoRef.current) {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
        const aspectRatio = videoWidth / videoHeight;
        setVideoAspectRatio(aspectRatio); 
      }

      // Check if the device is an iPad
      const isIPad = /Macintosh/i.test(navigator.userAgent);
      if (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        if (isIPad) {
          setOverlayDimensions({ width: '99%', height: '99%' });      
        } 
      } else {
        console.log("This is not an iPad device!");
      }
    };
  
    const handleResize = () => {
      calculateAspectRatio();
    };
  
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const captureImage = () => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
  
    const overlayWidth = parseFloat(overlayDimensions.width) / 100;
    const overlayHeight = parseFloat(overlayDimensions.height) / 100;
  
    const fragmentWidth = videoWidth * overlayWidth;
    const fragmentHeight = videoHeight * overlayHeight;
  
    const offsetX = (videoWidth - fragmentWidth) / 2;
    const offsetY = (videoHeight - fragmentHeight) / 2;
  
    canvas.width = fragmentWidth;
    canvas.height = fragmentHeight;
  
    canvas.getContext('2d').drawImage(
      videoRef.current,
      offsetX,
      offsetY,
      fragmentWidth,
      fragmentHeight,
      0,
      0,
      fragmentWidth,
      fragmentHeight
    );
  
    const imageData = canvas.toDataURL('image/jpeg', 0.8);
  
    if (step === 1) {
      setFrontImage(imageData);
      if (backImage) {
        setStep(5); // Skip to step 5 if back image already exists
      } else {
        setStep(2); // Proceed to step 2 to capture back image
      }
    } else if (step === 3) {
      setBackImage(imageData);
      setStep(4);
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const retakeImage = (side) => {
    if (side === 'front') {
      setFrontImage(null);
      setStep(1);
    } else if (side === 'back') {
      setBackImage(null);
      setStep(3);
    }
  };

  const handleSubmit = async () => {
    setSavingDocument(true);
    const formData = new FormData();
    formData.append('document[document_type]', 'driving_license');
    formData.append('front_photo', dataURItoBlob(frontImage));
    formData.append('back_photo', dataURItoBlob(backImage));

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/clients/${id}/documents`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        body: formData,
      });

      if (response.ok) {
        navigate(`/clients/${id}`);
      } else {
        console.error('Error creating document:', response);
      }
    } catch (error) {
      console.error('Error creating document:', error);
    } finally {
      setSavingDocument(false);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const tableHeaderStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    padding: '0 0 8px 0',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
  };
  const tableContentsStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'left',
    padding: 0,
    border: 'none',
  };

  const FooterButtons = ({ step, onCapture, onRetake, onKeep }) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'white',
          boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
          zIndex: 2000,
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {step === 1 || step === 3 ? (
          <Button variant="contained" sx={{ height: '42px' }} onClick={onCapture} startIcon={<PhotoCamera />}>
            Capture Image
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              sx={{ height: '42px', marginRight: '16px' }}
              onClick={onRetake}
              color="info"
              startIcon={<RefreshIcon />}
            >
              Retake Image
            </Button>
            <Button variant="contained" sx={{ height: '42px' }} onClick={onKeep}>
              Keep
            </Button>
          </>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        {client && <Link component={RouterLink} to={`/clients/${id}`} color="inherit">
          {client.name}
        </Link> }
        <Typography color="textPrimary">Driver's license</Typography>
      </Breadcrumbs>

      {client && step === 5 && (<Box sx={{ padding: '0 0 24px 0', marginBottom: '24px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Hidden lgDown>
          <Table sx={{ marginTop: '1rem' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderStyleDesktop}>Name</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Booking ref</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Branch</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={tableContentsStyleDesktop}>{client.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booking_ref}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.branch?.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
        <Hidden lgUp>
          <Table sx={{ marginTop: '1rem' }}>  
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{client.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Booking ref</TableCell>
                <TableCell>{client.booking_ref}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>{client.branch?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
      </Box>
      )}

      <Paper sx={{ padding: '0', borderRadius: '12px' }} position="relative">
        {(step === 1 || step === 3) && (
          <Box position="relative">
            <Box
              position="relative"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ borderRadius: '12px', overflow: 'hidden' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 0,
                  paddingBottom: videoAspectRatio ? `${(1 / videoAspectRatio) * 100}%` : '56.25%',
                  borderRadius: '12px', overflow: 'hidden',
                }}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  padding={2}
                  color="white"
                  sx={{ display: 'flex', height: 'calc((100% - '+overlayDimensions.height+')/ 2)', textAlign: 'center', justifyContent: 'center', alignItems: 'center', zIndex: 2000 }}
                >
                  <Typography variant="h6">
                    {step === 1
                      ? 'Front of the driver\'s license'
                      : 'Back of the driver\'s license'}
                  </Typography>
                  <Box
                    position="absolute"
                    sx={{ top: '32px', right: '32px' }}
                  >
                    <IconButton
                      color="inherit"
                      onClick={() => navigate(`/clients/${id}`)}
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  padding={2}
                  display="flex"
                  justifyContent="center"
                  sx={{ display: { xs: 'none', md: 'flex' }, height: 'calc((100% - '+overlayDimensions.height+')/ 2)', justifyContent: 'center', alignItems: 'center', zIndex: 2000 }}
                >
                  <Button variant="contained" sx={{ height: "42px" }} onClick={captureImage} startIcon={<PhotoCamera />}>
                    Capture Image
                  </Button>
                </Box>
                <Box
                  ref={overlayRef}
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{
                    transform: 'translate(-50%, -50%)',
                    width: overlayDimensions.width,
                    height: overlayDimensions.height,
                    borderRadius: '18px',
                    zIndex: 2,
                    boxShadow: '0 0 0 3600px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    borderRadius="18px"
                    bgcolor="transparent"
                    border="2px solid white"
                  />
                </Box>
              </Box>
            </Box>

            <FooterButtons
              step={step}
              onCapture={captureImage}
              onRetake={() => retakeImage(step === 1 ? 'front' : 'back')}
              onKeep={() => setStep(step === 1 ? 2 : 4)}
            />
          </Box>
        )}
        {step === 2 && (
          <Box position="relative">
            <Box
              position="relative"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ borderRadius: '12px', overflow: 'hidden' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 0,
                  paddingBottom: videoAspectRatio ? `${(1 / videoAspectRatio) * 100}%` : '56.25%',
                  borderRadius: '12px',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={frontImage}
                  alt="Front of Driver's License"
                  style={{
                    position: 'absolute',
                    top: 'calc((100% - ' + overlayDimensions.height + ') / 2)',
                    left: 'calc((100% - ' + overlayDimensions.width + ') / 2)',
                    width: overlayDimensions.width,
                    height: overlayDimensions.height,
                    objectFit: 'cover',
                    borderRadius: '18px',
                  }}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  padding={2}
                  color="black"
                  sx={{
                    display: 'flex',
                    height: 'calc((100% - '+overlayDimensions.height+')/ 2)',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2000,
                  }}
                >
                  <Typography variant="h6">Ensure all details are readable</Typography>
                  <Box position="absolute" sx={{ top: '32px', right: '32px' }}>
                    <IconButton
                      color="inherit"
                      onClick={() => navigate(`/clients/${id}`)}
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  padding={2}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    height: 'calc((100% - '+overlayDimensions.height+')/ 2)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2000,
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ height: '42px', marginRight: '16px' }}
                    onClick={() => retakeImage('front')}
                    color="info"
                    startIcon={<RefreshIcon />}
                  >
                    Retake Image
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ height: '42px' }}
                    onClick={() => setStep(3)}
                  >
                    Keep
                  </Button>
                </Box>
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{
                    transform: 'translate(-50%, -50%)',
                    width: overlayDimensions.width,
                    height: overlayDimensions.height,
                    border: '2px solid black',
                    borderRadius: '18px',
                  }}
                />
              </Box>
            </Box>
            <FooterButtons
              step={step}
              onCapture={captureImage}
              onRetake={() => retakeImage('front')}
              onKeep={() => setStep(3)}
            />
          </Box>
        )}
        {step === 4 && (
          <Box position="relative">
            <Box
              position="relative"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ borderRadius: '12px', overflow: 'hidden' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 0,
                  paddingBottom: videoAspectRatio ? `${(1 / videoAspectRatio) * 100}%` : '56.25%',
                  borderRadius: '12px',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={backImage}
                  alt="Back of Driver's License"
                  style={{
                    position: 'absolute',
                    top: 'calc((100% - ' + overlayDimensions.height + ') / 2)',
                    left: 'calc((100% - ' + overlayDimensions.width + ') / 2)',
                    width: overlayDimensions.width,
                    height: overlayDimensions.height,
                    objectFit: 'cover',
                    borderRadius: '18px',
                  }}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  padding={2}
                  color="black"
                  sx={{
                    display: 'flex',
                    height: 'calc((100% - '+overlayDimensions.height+')/ 2)',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2000,
                  }}
                >
                  <Typography variant="h6">Ensure all details are readable</Typography>
                  <Box position="absolute" sx={{ top: '32px', right: '32px' }}>
                    <IconButton
                      color="inherit"
                      onClick={() => navigate(`/clients/${id}`)}
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  padding={2}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    height: 'calc((100% - '+overlayDimensions.height+')/ 2)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2000,
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ height: '42px', marginRight: '16px' }}
                    onClick={() => retakeImage('back')}
                    color="info"
                    startIcon={<RefreshIcon />}
                  >
                    Retake Image
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ height: '42px' }}
                    onClick={() => setStep(5)}
                  >
                    Keep
                  </Button>
                </Box>
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{
                    transform: 'translate(-50%, -50%)',
                    width: overlayDimensions.width,
                    height: overlayDimensions.height,
                    border: '2px solid black',
                    borderRadius: '18px',
                  }}
                />
              </Box>
            </Box>
            <FooterButtons
              step={step}
              onCapture={captureImage}
              onRetake={() => retakeImage('back')}
              onKeep={() => setStep(5)}
            />
          </Box>
        )}
        {step === 5 && (
          <Box
            sx={{
              bgcolor: { xs: 'transparent', sm: '#FFFFFF' },
              borderRadius: '12px',
              padding: { xs: '16px 4px', sm: '16px' },
              position: 'relative',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, textAlign: { xs: 'center', sm: 'left' } }}>
              <Box sx={{ width: { xs: '100%', sm: 'auto' }}}>
                <Typography variant="h6">Ensure all details are readable</Typography>
                <Typography variant="body2">Retake photos if clarity is needed</Typography>
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<CheckIcon />} disabled={savingDocument}>
                  {savingDocument ? 'Saving...' : 'Confirm & Save'}
                </Button>
              </Box>
            </Box>
            <Grid container spacing={0} justifyContent="space-around" alignItems="center"
                  sx={{
                    bgcolor: { xs: 'transparent', sm: '#0000001F' },
                    borderRadius: '6px',
                    m: { xs: '16px 0' },
                  }}>
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    width: { xs: '98%', sm: '95%' },
                    margin: 'auto',
                    borderRadius: '12px',
                    border: '2px solid black',
                    overflow: 'hidden',
                    margin: { xs: '12px 0', sm: '32px 0', md: '64px 0'}
                  }}
                >
                  <img src={frontImage} alt="Front of Driver's License" style={{ display: 'block', width: '100%' }} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    width: { xs: '98%', sm: '90%' },
                    margin: 'auto',
                    borderRadius: '12px',
                    border: '2px solid black',
                    overflow: 'hidden',
                    margin: { xs: '12px 0', sm: '32px 0', md: '64px 0'}
                  }}
                >
                  <img src={backImage} alt="Back of Driver's License" style={{ display: 'block', width: '100%' }} />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="outlined" color="info" onClick={() => retakeImage('front')} sx={{ mr: { xs: 1, sm: 'auto' } }}>
                <RefreshIcon sx={{ mr: 1 }} />
                Retake Front Image
              </Button>
              <Button variant="outlined" color="info" onClick={() => retakeImage('back')}>
                <RefreshIcon sx={{ mr: 1 }} />
                Retake Back Image
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<CheckIcon />} disabled={savingDocument}>
                {savingDocument ? 'Saving...' : 'Confirm & Save'}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default AddDrivingLicense;