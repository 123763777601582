// app/javascript/components/PinCodeOverlay.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Popover } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const PinCodeOverlay = ({ user, onUnlock, onLogout }) => {
  const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePinCodeChange = (value) => {
    setPinCode(pinCode + value);
  };

  const handleUnlock = () => {
    if (pinCode === user.pin_code) {
      onUnlock();
      setPinCode('');
    } else {
      setError('Invalid PIN code');
    }
  };

  const handleLogoutClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoutConfirm = () => {
    onLogout();
    setAnchorEl(null);
  };

  const handleLogoutCancel = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(25, 25, 25, 0.95)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '24px',
          borderRadius: '8px',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          onClick={handleLogoutClick}
        >
          <LogoutIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleLogoutCancel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ zIndex: 99999 }}
        >
          <Box sx={{ padding: '16px' }}>
            <Typography>Log out?</Typography>
            <Box sx={{ marginTop: '8px' }}>
              <Button variant="outlined" onClick={handleLogoutCancel} sx={{ marginRight: '8px' }}>
                Cancel
              </Button>
              <Button variant="contained" color="error" onClick={handleLogoutConfirm}>
                Yes
              </Button>
            </Box>
          </Box>
        </Popover>
        <Typography variant="h6" gutterBottom>
          Enter PIN Code to unlock
        </Typography>
        <TextField
          type="password"
          value={pinCode}
          error={!!error}
          helperText={error}
          fullWidth
          margin="normal"
        />
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '12px', marginTop: '16px' }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
            <Button key={number} size="large" color="info" variant="outlined" onClick={() => handlePinCodeChange(number)}>
              {number}
            </Button>
          ))}
          <Button variant="outlined" size="large" color="secondary" onClick={() => setPinCode('')}>
            Clear
          </Button>
          <Button variant="outlined" size="large" color="info" onClick={() => handlePinCodeChange(0)}>
            0
          </Button>
          <Button variant="contained" size="large" color="primary" onClick={handleUnlock}>
            Unlock
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PinCodeOverlay;