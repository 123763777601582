import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Modal, Box, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

const Header = ({ user, onLogout }) => {
  const [open, setOpen] = useState(false);

  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleLogoutConfirm = () => {
    onLogout();
    setOpen(false);
  };

  const handleLogoutCancel = () => {
    setOpen(false);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
      <Toolbar sx={{ width: '100%', maxWidth: '1152px', margin: '0 auto', padding: '12px' }}>
        <img src="/images/logo.svg" alt="Logo" style={{ maxHeight: '56px', maxWidth: '35%' }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ marginRight: '16px' }}>
          {user.full_name || user.email}
        </Typography>
        <IconButton component={RouterLink} to="/profile" color="inherit" sx={{ marginRight: '16px' }}>
          <SettingsIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleLogoutClick}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
      <Modal open={open} onClose={handleLogoutCancel}>
        <Box sx={{ 
          position: 'absolute',
          borderRadius: '12px',
          width: '444px',
          maxWidth: '100vw',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: '16px', 
        }}>
          <Typography variant="h6" component="h2"  sx={{ p: '0 8px' }} gutterBottom>
            Confirm log out?
          </Typography>
          <Typography sx={{ p: '8px' }}>Are you sure you want to log out?</Typography>
          <Box sx={{ mt: '16px' }}>
            <Button variant="outlined" color="secondary" onClick={handleLogoutCancel} sx={{ width: '49%', marginRight: '2%' }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleLogoutConfirm} sx={{ width: '49%' }}>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </AppBar>
  );
};

export default Header;